import Vue from 'vue';
import axios from 'axios';
import VueKakaoSdk from 'vue-kakao-sdk'
import App from './App.vue';
import router from './router';
import store from './store';
import VueSession from 'vue-session';
import VueCookies from 'vue-cookies';
import VueApexCharts from 'vue-apexcharts';
import ('@/assets/css/default.css');
import ('@/assets/css/page.css');
import ('@/assets/css/font.css');
Vue.config.productionTip = false;
Vue.prototype.$axios = axios
Vue.config.productionTip = false
const apiKey = '28ae11aca1ec7bde8180db5ad92a69eb'
Vue.use(VueApexCharts);
Vue.use(VueKakaoSdk, { apiKey });
Vue.use(VueCookies);


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

axios.defaults.withCredentials = true;