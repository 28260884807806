import Vue from "vue";
import VueKakaoSdk from "vue-kakao-sdk";
import VueRouter from "vue-router";
import Element from "element-ui";
import VueCookies from "vue-cookies";

import "element-ui/lib/theme-chalk/index.css";
Vue.use(Element, { size: "small", zIndex: 3000 });
Vue.use(VueRouter);

const apiKey = "28ae11aca1ec7bde8180db5ad92a69eb";

Vue.use(VueKakaoSdk, { apiKey });

const routes = [
  {
    path: "/",
    name: "loginLayout",
    component: () => import("@/layouts/LoginLayout.vue"),
    children: [
      {
        path: "/",
        name: "introStep1",
        component: () => import("../views/intro_step1.vue"),
      },
      {
        path: "/login",
        name: "login",
        component: () => import("../views/login.vue"),
      },
    ],
  },
  {
    path: "/index",
    name: "defaultLayout",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index.vue"),
      },
      //이달의 캠페인
      {
        path: "/campaign",
        name: "campaign",
        component: () => import("../views/campaign.vue"),
      },
      //캠페인
      {
        path: "/campaign2",
        name: "campaign2",
        component: () => import("../views/campaign2.vue"),
      },
      //캠페인 카테고리
      {
        path: "/campaignCategory",
        name: "campaignCategory",
        component: () => import("../views/campaignCategory.vue"),
      },
      //캠페인 상세페이지
      {
        path: "/campaignDetail",
        name: "campaignDetail",
        component: () => import("../views/campaignDetail.vue"),
      },
      //캠페인설명 상세페이지
      {
        path: "/campaignDescription",
        name: "campaignDescription",
        component: () => import("../views/campaignDescription.vue"),
      },
      //캠페인 신청
      {
        path: "/campaignApply",
        name: "campaignApply",
        component: () => import("../views/campaignApply.vue"),
      },
      //캠페인 신청 결과 페이지
      {
        path: "/campaignApplyResult",
        name: "campaignApplyResult",
        component: () => import("../views/campaignApplyResult.vue"),
      },
      //참여 캠페인
      {
        path: "/engageCampaign",
        name: "engageCampaign",
        component: () => import("../views/engageCampaign.vue"),
      },
      {
        path: "/engageCampaignDetail",
        name: "engageCampaignDetail",
        component: () => import("../views/engageCampaignDetail.vue"),
      },
      //공지사항
      {
        path: "/notice",
        name: "notice",
        component: () => import("../views/notice.vue"),
      },
      {
        path: "/noticeDetail",
        name: "noticeDetail",
        component: () => import("../views/noticeDetail.vue"),
      },
      //포인트
      {
        path: "/point",
        name: "point",
        component: () => import("../views/point.vue"),
      },
      {
        path: "/pointUse",
        name: "pointUse",
        component: () => import("../views/pointUse.vue"),
      },
      {
        path: "/pointScan",
        name: "pointScan",
        component: () => import("../views/pointScan.vue"),
      },
      {
        path: "/pointUseList",
        name: "pointUseList",
        component: () => import("../views/pointUseList.vue"),
      },
      //마이페이지
      {
        path: "/mypage",
        name: "mypage",
        component: () => import("../views/mypage.vue"),
      },
      {
        path: "/member",
        name: "member",
        component: () => import("../views/member.vue"),
      },
      {
        path: "/storeList",
        name: "storeList",
        component: () => import("../views/storeList.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
