<template>
  <div id="app">
      <router-view :load-script="loadScript" />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      showSplash: true, // 초기에 스플래시 이미지를 표시할지 여부를 제어하는 데이터
    };
  },
  mounted() {
    // 여기에서 초기 로딩 작업을 수행합니다.
    // 작업이 완료되면 showSplash 데이터를 false로 변경하여 스플래시 이미지를 숨깁니다.
    setTimeout(() => {
      this.showSplash = false;
    }, 3000); // 예시로 3초 후에 스플래시 이미지를 숨기도록 설정
  },
  methods: {
    loadScript() {
      const script = document.createElement("script");
      script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=28ae11aca1ec7bde8180db5ad92a69eb&autoload=false";
      script.onload = () => window.kakao.maps.load(() => this.loadMap());
      document.head.appendChild(script);
    },
    loadMap() {
      // 이곳에 지도 로딩과 관련된 작업을 수행하세요.
    },
  },
};
</script>

<style>
/* 필요한 스타일을 추가하세요. */
</style>